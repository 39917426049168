import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";

function Layout({ children, bgColor = "lightgreen" }) {
  // bg-white bg-lightgreen
  useLayoutEffect(() => {
    document.body.classList.add(`bg-${bgColor}`);
  }, [bgColor]);

  return (
    <div className="max-w-7xl mx-auto">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-DSPJ1V88J7"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-DSPJ1V88J7');
          `}
        </script>
      </Helmet>
      <Header />

      {children}

      <Footer />
    </div>
  );
}

export default Layout;
