import React from "react";

function CtaLink({ children, bgColor = "red", href, outline }) {
  return (
    <a
      className={`text-base px-5 py-3 rounded-lg font-bold uppercase tracking-tight outline-none border-4 border-solid border-${bgColor} ${
        outline ? `text-${bgColor}` : `text-white bg-${bgColor}`
      }`}
      href={href}
    >
      {children}
    </a>
  );
}

export default CtaLink;
