import React from "react";
import classNames from "classnames";

function BlockTitle({
  id,
  children,
  small,
  large,
  white,
  center,
  marginBottom
}) {
  return (
    <h2
      id={id}
      className={classNames({
        [small ? "text-lg" : "text-2xl"]: true,
        [small ? "text-xl" : "sm:text-4xl"]: true,
        [large ? "text-2xl md:text-4xl lg:text-5xl" : ""]: large,
        "text-center": center,
        "mb-10": marginBottom,
        "sm:mb-12": marginBottom,
        "text-white": white
      })}
    >
      {children}
    </h2>
  );
}

export default BlockTitle;
