import React from "react";
import CtaLink from "./CtaLink";

const calendlyUrl = "https://calendly.com/handpickedcherries/30min";

function CtaBookMeeting(props) {
  return (
    <CtaLink {...props} href={calendlyUrl}>
      Varaa maksuton esittely
    </CtaLink>
  );
}

export default CtaBookMeeting;
