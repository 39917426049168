import React from "react";
import "./Header.css";
import Block from "./Block";
import CtaBookMeeting from "./CtaBookMeeting";

function Header() {
  return (
    <div className="Header">
      <Block>
        <nav className="items-center justify-center md:justify-between flex">
          <img
            src="/images/hpc-logo-2020-stacked.svg"
            alt=""
            className="Header-logo"
          />
          <div className="hidden md:block">
            <CtaBookMeeting bgColor="gray" outline />
          </div>
        </nav>
      </Block>
    </div>
  );
}

export default Header;
