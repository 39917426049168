import React from "react";
import Block from "./Block";

function BlockFull({ children, bgColor }) {
  return (
    <div className="xl:-mx-100pct" style={{ backgroundColor: bgColor }}>
      <div className="max-w-7xl mx-auto">
        <Block bgColor={bgColor}>{children}</Block>
      </div>
    </div>
  );
}

export default BlockFull;
