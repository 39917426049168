import React from "react";

function Block({ children, bgColor = "" }) {
  return (
    <div
      className="Block w-full py-8 md:py-16 px-8"
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </div>
  );
}

export default Block;
