import React from "react";
import BlockFull from "./BlockFull";

function Footer() {
  return (
    <BlockFull bgColor="#742746">
      <div className="my-6 md:my-12 flex flex-col md:flex-row text-white">
        <div className="flex-1 flex justify-between flex-col mb-12 md:mb-0 items-center md:items-start">
          <img
            src="/images/hpc-logo-2020-stacked-white.svg"
            alt="Handpicked Cherries"
            className="footer-logo mb-6 md:mb-0"
          />
          <div className="text-center md:text-left">
            Parempi asiakaskokemus.
            <br />
            Enemmän myyntiä.
          </div>
        </div>
        <div className="text-center md:text-left md:mr-12">
          Handpicked Cherries Oy
          <br />
          Helsinki FINLAND
          <br />
          <br />
          Krista Paloheimo, CEO
          <br />
          <a href="tel:+358408390042">+358 40 839 0042</a>
          <br />
          <a href="mailto:krista@handpickedcherries.com">
            krista@handpickedcherries.com
          </a>
          <br />
          <br />
          VAT no. FI27570847
        </div>
      </div>
    </BlockFull>
  );
}

export default Footer;
